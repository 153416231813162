<template>
    <div class="p-grid">
        <div class="p-col-12 p-md-12">
            <div class="card p-fluid">
                <h4 v-if="!isProd">RFP Insights</h4>
                <h4 v-else>Opportunities</h4>
            </div>
            <div v-if="!isProd" class="card p-fluid">
                <div class="calender-wrapper">
                    <div class="calendar-container">
                        <Calendar v-model="dates" selectionMode="range" :manualInput="false"
                            :date-format="dd / MM / yyyy" showButtonBar />
                    </div>
                    <Button :disabled="!dates || dates?.length == 0 || dates[0] == null || dates[1] == null"
                        @click="getRfpListInsights">Get Data</Button>

                </div>

                <DataTable :value="products" showGridlines :scrollable="true" responsiveLayout="scroll" stripedRows
                    scrollHeight="500px" :loading="loading" :resizableColumns="true">

                    <template #empty>
                        <div class="empty-state">
                            <i class="pi pi-info-circle text-gray-500 text-xl mb-2"></i>
                            <p class="m-0">No records found for the selected date range</p>
                        </div>
                    </template>

                    <Column field="id" header="ID" headerStyle="width: 115px;" sortable></Column>
                    <Column field="name" sortable>
                        <template #header>
                            Name
                            <span style="margin-left: 12px;font-size: 0.8em; font-weight:normal;"> (Click to view
                                details)</span>
                        </template>
                        <template #body="slotProps">
                            <div @click="navigateToRfpDetails(slotProps.data.id)" class="opportunityLink">
                                {{ slotProps.data.name }}
                            </div>
                        </template>
                    </Column>
                    <Column field="email" header="Email" sortable></Column>
                    <Column field="status_display_name" header="Status" sortable></Column>
                    <Column field="tags.service" header="Service" sortable></Column>
                    <Column field="tags.industry" header="Industry" sortable></Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import RfpInsightsService from './../service/RfpInsightsService';

export default {
    data() {
        return {
            products: null,
            dates: null,
            listPayload: null,
            isProd: false,
        }
    },

    productService: null,
    rfpInsightsService: null,
    async created() {
        this.rfpInsightsService = new RfpInsightsService();
        this.setInitialDateRange();
        this.isProd = process.env.VUE_APP_ENV_TYPE === "PROD";
    },
    methods: {
        // Navigate to RFP details page
        navigateToRfpDetails(id) {
            if (process.env.VUE_APP_ENV_TYPE == "DEV") {
                window.open(`${process.env.VUE_APP_RFPERFECT_FRONTEND_URL}/details?id=${id}`, '_blank');
            } else if (process.env.VUE_APP_ENV_TYPE == "UAT") {
                window.open(`${process.env.VUE_APP_RFPERFECT_FRONTEND_URL}/details?id=${id}`, '_blank');
            } else if (process.env.VUE_APP_ENV_TYPE == "PROD") {
                window.open(`${process.env.VUE_APP_RFPERFECT_FRONTEND_URL}/details?id=${id}`, '_blank');
            }
        },


        // Set initial date range
        setInitialDateRange() {
            const today = new Date();
            const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

            this.dates = [firstDayOfMonth, today];
            this.getRfpListInsights(); // Call the API with initial date range
        },

        // Get RFP list insights
        async getRfpListInsights() {
            this.listPayload = {
                startDate: this.dates[0]?.toISOString(),  // First date in array
                endDate: this.dates[1]?.toISOString(),  // Second date in array
                partnerId: localStorage.getItem("accountId"),
            }
            console.log("This UserData >> :", this.listPayload);
            const res = await this.rfpInsightsService.getRfpListInsights(this.listPayload);
            this.products = res.data;
        },
    },
}
</script>



<style lang="scss" scoped>
.opportunityLink {
    color: darkgreen;
    text-decoration: underline;
    cursor: pointer;
}


.calender-wrapper {
    display: flex;
    margin-top: 10px;
    margin-bottom: 16px;
    gap: 10px;
}

.calendar-container {
    width: 200px;
}

.empty-state {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}
</style>
